.counsellingForm{
    .formInput{
        margin-bottom: 30px;
        input,
        textarea,
        select{
            background: #F3F0EA;
            height: 50px;
            padding: 6px 15px;
            color: #666666;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 14px;
            }
        }
        
        select{
            color: #bbb5a5;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: #F3F0EA url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px;
            padding: 15px;
        }
        p{
            text-transform: uppercase;
            font-size: 12px;
            color: red;
        }
    }
    .formInput1{
        margin-bottom: 30px;
        input,
        textarea,
        select{
            background: #F3F0EA;
            height: 50px;
            padding: 6px 60px !important;
            color: #666666;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 14px;
            }
        }
        
        select{
            color: #bbb5a5;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: #F3F0EA url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px;
            padding: 15px;
        }
        p{
            text-transform: uppercase;
            font-size: 12px;
            color: red;
        }
    }
    
    button{
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        padding: 9px 25px;
        border: 2px solid transparent;
        text-transform: capitalize;
        display: inline-block;
        transition: all .3s;
        background: #c0b596;
        &:hover{
            background:#d4c291;
        }
    }
}
.react-tel-input .selected-flag{
    width: 50px !important;
}
.react-tel-input .form-control {
    width: 100% !important;
}
.client-file-area-left-side{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.client-file-area-left-side p{
    font-size: 15px;
    font-weight: 550;
    color: #282e3f;
}
.signon-bottom-area1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.client-file-area-left-side1{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.client-file-area-left-side1 p{
    font-size: 15px;
    font-weight: 550;
    color: #282e3f;
}
/* checkbox */
.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 30px;
      height: 30px;
      background: white;
      border: 1px solid #c0b596;
    }
  
    // Box hover
    &:hover + label:before {
      background: white;
      border: 1px solid #c0b596;
    }
    
    // Box focus
   /*  &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    } */
  
    // Box checked
    &:checked + label:before {
      background: #c0b596;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 9px;
      top: 13px;
      background: white;
      width: 4px;
      height: 4px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  
  
  .title {
    text-align: center;
    color: rgb(69, 113, 236);
  }
  .checkbo-label-A{
      color: #282e3f;
      font-size: 16px;
      font-weight: 550;
      padding-right: 10px;
  }
  .clear-button{
   background-color: #c0b596;
   padding: 10px 15px 10px 15px;   
   color: white;
   cursor: pointer;
   border-radius: 5px;
  }
  .customer-file-loader-with-button{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
  }