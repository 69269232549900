.SignUpArea{
    background: url(../../images/contact/cumtomer-sign-up.jpg) center center/cover no-repeat local;
    position: relative;
    background-attachment: fixed;
    padding: 100px 0px;
    &::before{
        content: "";
        background-color: rgba(21, 26, 48, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .contactInfo{
        @media (max-width:768px) {
            margin-bottom: 40px;
        }
        span{
            font-size: 16px;
            color: #ada282;
        }
        h2{
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #fff;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }
        h6{
            font-size: 18px;
            color: #c0b596;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #ddd;
            line-height: 30px;
        }
    }
}
.profile-info .profile-info-company-logo {
    display: none !important;
  }
  .calendly-inline-widget{
    scrollbar-color: red yellow;
}
.password-area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: red;
    font-size: 600;
}
.password-area1{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap; 
}
.signon-bottom-area{
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
#emailField{
    padding:17px;
    padding-left:60px;
    padding-right: 40px;
  border-right: 1px solid #c0b596;
  background-image:url('../../images/signIn/03-01.png') ;
  background-repeat:no-repeat;
  background-position:left center;
  outline:0; 
  }
.field-design2{
  background: rgba(192, 181, 150, 0.2);
    height: 50px;
    padding: 6px 15px;
    color: white;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, 0.5);
}
.field-design1{
    background: rgba(192, 181, 150, 0.2);
      height: 50px;
      padding: 6px 15px;
      color: white;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid rgba(192, 181, 150, 0.5);
  }
.field-design2 img{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.password-image2{
    width: 30px;
    cursor: pointer;
}
.password-image1{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.field-design2 input{
    width: 80% !important;
    border-color: transparent !important;
    outline: none !important;
}
.checkbow-style{
    height: 20px;
    width: 20px;
    background-color: #c0b596 !important;
    margin-top: 8px;
   
}
.checkbow-style-area{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
input[type=checkbox]:after {
    position: absolute;
    width: 20px;
    height: 20px;
  margin-top: 8px;
    top: 0;
    content: " ";
    background-color: white;
    color: black;
    display: inline-block;
    visibility: visible;
    padding: 0px 0px 3px 3px !important;
    border-radius: 3px;
  }
  
  input[type=checkbox]:checked:after {
	  content: "✓";
      font-size: 16px;
      font-weight: 600;
  
  }
.checkbow-style-area input:checked ~ .checkmark:after {
    display: block;
  }
.field-design input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.field-design1 input{
    width: 78% !important;
    border-color: transparent !important;
    outline: none !important;
}
.field-design1 input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
/* checkbox */
.styled-checkbox-signup {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid #c0b596;
    }
  
    // Box hover
    &:hover + label:before {
      background: white;
      border: 1px solid #c0b596;
    }
    
    // Box focus
   /*  &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    } */
  
    // Box checked
    &:checked + label:before {
      background: #c0b596;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 9px;
      background: white;
      width: 3px;
      height: 3px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
    
  .formInput15{
    margin-bottom: 30px;
    input,
    textarea,
    select{
      background: rgba(192, 181, 150, .2) !important;
        height: 50px !important;
        padding: 6px 60px !important;
        color: #A19C91 !important;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid rgba(192, 181, 150, .5);
        &:focus{
            -webkit-box-shadow: none;
            box-shadow: none;
            border-color: #c0b596;
        }
        &::-webkit-input-placeholder{
          color: #bbb5a5 !important;
            font-size: 14px;
        }
    }
    
    select{
      color: #bbb5a5 !important;
        position: relative;
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: #F3F0EA url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
    }
    textarea{
        height: 150px;
        padding: 15px;
    }
    p{
        text-transform: uppercase;
        font-size: 12px;
        color: red;
    }
}
  .formInput16{
    margin-bottom: 30px;
    input,
    textarea,
    select{
      background: rgba(192, 181, 150, .2) !important;
        height: 50px !important;
        padding: 6px 60px !important;
        color: #666666 !important;
        border-radius: 0;
        font-weight: 550;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid rgba(192, 181, 150, .5);
        &:focus{
            -webkit-box-shadow: none;
            box-shadow: none;
            border-color: #666666;
        }
        &::-webkit-input-placeholder{
          color: #666666 !important;
            font-size: 14px;
        }
    }
    
    select{
      color: #bbb5a5 !important;
        position: relative;
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: #F3F0EA url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
    }
    textarea{
        height: 150px;
        padding: 15px;
    }
    p{
        text-transform: uppercase;
        font-size: 12px;
        color: red;
    }
}
.react-tel-input .flag-dropdown:hover{
      background: rgba(192, 181, 150, .2) !important;
}
.react-tel-input .flag-dropdown.open{
      background: rgba(192, 181, 150, .2) !important;
}
.react-tel-input .flag-dropdown.open .selected-flag{
    background: rgba(192, 181, 150, .2) !important;
}
.react-tel-input .flag-dropdown.open .selected-flag:hover{
    background: rgba(192, 181, 150, .2) !important;
}
.react-tel-input .flag-dropdown {
  background: rgba(192, 181, 150, .2) !important;
}
.react-tel-input .flag-dropdown:hover {
  background: rgba(192, 181, 150, .2) !important;
}
.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #bbb5a5 !important;
}
.react-tel-input .country-list{
    background: rgba(192, 181, 150, .2) !important;
}

.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #302E34;
  width: 40px !important;
  height: 40px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.button-loaader-area-cutomer-signup{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}