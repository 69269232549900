.userPanleArea444{
    background: url(../../images/contact/1.jpg) center center/cover no-repeat local;
    position: relative;
    background-attachment: fixed;
    padding: 100px 0px;
    &::before{
        content: "";
        background-color: rgba(21, 26, 48, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .user-panel-new-left-area{
        height: 100%;
        border: 1px solid white;
        border-radius: 10px;
        color:white;
        .rider_panel_page_main_area-left-area-top-edit-btn-profile{
            width:100%;
            display: flex;
            justify-content: flex-end;
            padding: 15px 15px 5px 5px;
            .rider-panel-edit-profile-btn{
              
                font-size: 14px;
                color: white;
                border: 1px solid white;
                padding: 0px 10px 0px 10px;
                border-radius:3px;
                cursor: pointer;
              }
          }
          
.rider-panel-edit-profile{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    }
    .rider-panel-edit-profile-img img {
    
      
    }
    .rider-panel-edit-profile-img .rider-panel-edit-status {
     
      border-radius: 50%;
      display: flex;
      position: relative;
      top: -40px;
      right: -90px;
      cursor: pointer;
    }
    .rider-panel-edit-profile-name-text{
      color:white;
      font-size:20px;
    }
    .rider-panel-edit-profile-name{
      margin-top: -27px;
      border-bottom: 1px solid white;
      padding-bottom: 30px;
    }
    .rider-panel-gender-and-location-area{
        border-bottom: 1px solid white;
        padding: 30px 20px 30px 20px;
        p{
            font-size: 14px;
           margin-bottom: 10px;
            color:white;
        }
      }
      .rider-panel-gender-and-location-area1{
     
        padding: 30px 20px 30px 20px;
        p{
            font-size: 14px;
           margin-bottom: 10px;
            color:white;
        }
      }
      

         
    }
    .user-panel-new-right-area{
        height: 100%;
        border: 1px solid white;
        border-radius: 10px;
        color:white; 
    }
    .contactInfo{
        @media (max-width:768px) {
            margin-bottom: 40px;
        }
        span{
            font-size: 16px;
            color: #ada282;
        }
        h2{
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #fff;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }
        h6{
            font-size: 18px;
            color: #c0b596;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #ddd;
            line-height: 30px;
        }
    }
}
.profile-info .profile-info-company-logo {
    display: none !important;
  }
  .calendly-inline-widget{
    scrollbar-color: red yellow;
}
.password-area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: red;
    font-size: 600;
}
.password-area1{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap; 
}
.signon-bottom-area{
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
#emailField{
    padding:17px;
    padding-left:60px;
    padding-right: 40px;
  border-right: 1px solid #c0b596;
  background-image:url('../../images/signIn/03-01.png') ;
  background-repeat:no-repeat;
  background-position:left center;
  outline:0; 
  }
.field-design{
  background: rgba(192, 181, 150, 0.2);
    height: 50px;
    padding: 6px 15px;
    color: white;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, 0.5);
}
.field-design1{
    background: rgba(192, 181, 150, 0.2);
      height: 50px;
      padding: 6px 15px;
      color: white;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid rgba(192, 181, 150, 0.5);
  }
.field-design img{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.password-image2{
    width: 30px;
    cursor: pointer;
}
.password-image1{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.field-design input{
    width: 85% !important;
    border-color: transparent !important;
    outline: none !important;
}

.field-design input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.field-design1 input{
    width: 78% !important;
    border-color: transparent !important;
    outline: none !important;
}
.field-design1 input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}


/* <style>
.ant-tabs-tab {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 32px 0 0;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}
<style>
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: inline-block;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: auto;
    flex: auto;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transform: translate(0);
    transform: translate(0);
}
<style> */
.ant-tabs{
    color: white !important;
}
.ant-tabs-tab-btn {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-bottom: 10px !important;
    margin-bottom: -7px !important;
    font-weight: 550 !important;
}
.ant-tabs-tab-btn:hover {
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-weight: 550 !important;
    color: white !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: white !important;
    font-weight: 550 !important;
    border-bottom: 3px solid white !important; 
    padding-bottom: 7px !important;
    margin-bottom: -7px !important;
    /* border-bottom: 3px solid white !important; */
}
.ant-tabs-tab:hover {
    color: white !important; 
}
/* .ant-tabs-tab{
    border-bottom: 3px solid white !important;   
} */
 .ant-tabs-tab:active{
    border-bottom: 3px solid white !important;   
} 
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: transparent !important;
    border: transparent !important;
}
.quotaion-table-pagination-area{
    margin: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap-reverse;
    font-size: 14px;
    color: white;
}
.pagination-table-quotaion-dec{
    border: 1px solid white;
    padding: 0px 10px 0px 10px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}
.pagination-table-quotaion-dec i{
cursor: pointer;
}
.pagination-table-quotaion-inc i{
    cursor: pointer;
    }
    
.pagination-table-quotaion-inc{
    border: 1px solid white;
    border-left: transparent !important;
    padding: 0px 10px 0px 10px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px; 
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border:1px solid white !important 
}
.open-file-button-user-panel{
    padding: 10px;
    border: 4px solid white;
    color: white;
    border-radius: 10px;
    margin-top: -40px;
    cursor: pointer;
}

.loader-for-tabel-invoce{background-color:transparent}.loader-for-tabel-invoce,.loader-for-tabel-invoce:after{position:relative;height:500px;display:flex;justify-content:center;align-items:center}.loader-for-tabel-invoce:after{background:url(https://react.semantic-ui.com/images/wireframe/paragraph.png) 0 no-repeat local;background-color:#fff;opacity:.2}.loader1188{border-radius:50%;border:10px solid #f3f3f3;border-top-color:#302e34;width:60px!important;height:60px!important;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite;opacity:1!important}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(1turn)}}@keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}