
@media (max-width:7000px) and (min-width:991px){
    .ipad-menu-services{
        color: white !important;
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
        border: 2px solid white;
        border-radius: 5px;
        max-width: 300px;
        cursor: pointer;
        text-align: left;
        padding-left: 15px;
        margin-bottom: 10px;
        padding-top: 8px;
        padding-bottom: 7px;
        
    }
    .admin-menu-areay-heading{
        font-size: 30px; 
    }
}
@media (max-width:991px) and (min-width:0px){
    .admin-menu-areay-heading{
        font-size: 22px; 
    }
    .ipad-menu-services{
        color: rgb(90, 77, 77) !important;
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;
        border: 2px solid white;
        border-radius: 5px;
        
        cursor: pointer;
        text-align: left;
        padding-left: 15px;
        margin-bottom: 10px;
        padding-top: 8px;
        padding-bottom: 7px;
        
    }  
}
/* .ipad-customer-menu-items{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
} */