
.switch99 {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }
  
  .switch99 input {display:none;}
  
  .slider99 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #666666!important;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider99:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider99 {
    background-color:  #d4c291 !important;
  }
  
  input:focus + .slider99 {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider99:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  
  /*------ ADDED CSS ---------*/
  .on99
  {
    display: none;
  }
  
  .on99
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 30%;
    font-size: 14px;
  }
  .off99
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 70%;
    font-size: 14px;
  }
  
  input:checked+ .slider99 .on99
  {display: block;}
  
  input:checked + .slider99 .off99
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider99.round99 {
    border-radius: 34px;
  }
  
  .slider99.round99:before {
    border-radius: 50%;}