.progress-bar {
    height: 8px;
    background: #4caf50;
    width: 0%;
  }
  .progress-container {
      position: fixed;
      top: 0;
    width: 100%;
    height: 8px;
    background: #ccc;
  }
  .video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}