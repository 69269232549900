.SignUpCopArea{
    background: url(../../images/contact/corporate-sign-up.jpg) center center/cover no-repeat local;
    position: relative;
    background-attachment: fixed;
    padding: 100px 0px;
    &::before{
        content: "";
        background-color: rgba(21, 26, 48, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .contactInfo{
        @media (max-width:768px) {
            margin-bottom: 40px;
        }
        span{
            font-size: 16px;
            color: #ada282;
        }
        h2{
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #fff;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }
        h6{
            font-size: 18px;
            color: #c0b596;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #ddd;
            line-height: 30px;
        }
    }
}
.profile-info .profile-info-company-logo {
    display: none !important;
  }
  .calendly-inline-widget{
    scrollbar-color: red yellow;
}
.password-area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: red;
    font-size: 600;
}
.password-area1{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap; 
}
.signon-bottom-area{
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
#emailField{
    padding:17px;
    padding-left:60px;
    padding-right: 40px;
  border-right: 1px solid #c0b596;
  background-image:url('../../images/signIn/03-01.png') ;
  background-repeat:no-repeat;
  background-position:left center;
  outline:0; 
  }
.field-design2{
  background: rgba(192, 181, 150, 0.2);
    height: 50px;
    padding: 6px 15px;
    color: white;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, 0.5);
}
.field-design3{
    background: rgba(192, 181, 150, 0.2);
      height: 50px;
      padding: 6px 15px;
      color: white;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid rgba(192, 181, 150, 0.5);
  }
.field-design1{
    background: rgba(192, 181, 150, 0.2);
      height: 50px;
      padding: 6px 15px;
      color: white;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid rgba(192, 181, 150, 0.5);
  }
  .field-design2 img{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.field-design3 img{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.password-image2{
    width: 30px;
    cursor: pointer;
}
.password-image1{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.field-design2 input{
    width: 80% !important;
    border-color: transparent !important;
    outline: none !important;
}
.field-design3 select{
    width: 80% !important;
    border-color: transparent !important;
    outline: none !important;
}
.checkbow-style{
    height: 20px;
    width: 20px;
    background-color: #c0b596 !important;
    margin-top: 8px;
   
}
.checkbow-style-area{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
input[type=checkbox]:after {
    position: absolute;
    width: 20px;
    height: 20px;
  margin-top: 8px;
    top: 0;
    content: " ";
    background-color: white;
    color: black;
    display: inline-block;
    visibility: visible;
    padding: 0px 0px 3px 3px !important;
    border-radius: 3px;
  }
  
  input[type=checkbox]:checked:after {
	  content: "✓";
      font-size: 16px;
      font-weight: 600;
  
  }
.checkbow-style-area input:checked ~ .checkmark:after {
    display: block;
  }
.field-design input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.field-design1 input{
    width: 78% !important;
    border-color: transparent !important;
    outline: none !important;
}
.field-design1 input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.dropdownselectValue{
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAGCAYAAAARx7TFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjk1QjUzNDMzOEIwODExRTdBMkIzQTQyMTQzNjA5MEJBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjk1QjUzNDM0OEIwODExRTdBMkIzQTQyMTQzNjA5MEJBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTVCNTM0MzE4QjA4MTFFN0EyQjNBNDIxNDM2MDkwQkEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTVCNTM0MzI4QjA4MTFFN0EyQjNBNDIxNDM2MDkwQkEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7Dl6ZeAAAAY0lEQVR42mKcN3/+GQYGhorEhIQ9DGhg/oIF5UDKmAlI3APiVUABFywKOoD4LBPQhDAgYw+yQiQFIBs6Gf///w/TuQpIgRStBuI0mAKQHFwRksJQZAVgAFKEjIEeCUUXAwgwAC30Q/f8tXHIAAAAAElFTkSuQmCC) no-repeat calc(100% - 15px) center !important;
}
/* 
background: #48464c url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAGCAYAAAARx7TFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjk1QjUzNDMzOEIwODExRTdBMkIzQTQyMTQzNjA5MEJBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjk1QjUzNDM0OEIwODExRTdBMkIzQTQyMTQzNjA5MEJBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTVCNTM0MzE4QjA4MTFFN0EyQjNBNDIxNDM2MDkwQkEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTVCNTM0MzI4QjA4MTFFN0EyQjNBNDIxNDM2MDkwQkEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7Dl6ZeAAAAY0lEQVR42mKcN3/+GQYGhorEhIQ9DGhg/oIF5UDKmAlI3APiVUABFywKOoD4LBPQhDAgYw+yQiQFIBs6Gf///w/TuQpIgRStBuI0mAKQHFwRksJQZAVgAFKEjIEeCUUXAwgwAC30Q/f8tXHIAAAAAElFTkSuQmCC) no-repeat calc(100% - 15px) center; */