.blogPostArea{
    padding: 100px 0px;
    &.blogPostLeftArea{
        padding-bottom: 40px;
        .searchSidebarWrapper{
            @media (max-width:768px) {
                margin-top: 0;
            }
        }
        .instagramWrap{
            @media (max-width:768px) {
                margin-bottom: 60px;
            }
        }
    }
    &.blogFullWidth{
        padding-bottom: 40px;
    }
}
.cetagoryWrap{
    background: #f5f5f5;
    margin-bottom: 60px;
    h3{
        background: #ddd;
        padding: 10px 20px 20px;
        position: relative;
        font-size: 24px;
        margin-bottom: 0;
        &::before{
            content: '';
            position: absolute;
            left: 30px;
            top: 100%;
            border: 15px solid transparent;
            border-top-width: 13px;
            border-top-color: rgba(0, 0, 0, .1);
        }
    }
    ul{
        padding: 15px 30px 20px;
        li{
            border-bottom: 1px solid #ddd;
            &:last-child{
                border-bottom: none;
            }
            a{
                font-size: 16px;
                color: #333;
                display: block;
                padding: 15px 0 10px;
                transition: all .3s;
                &:hover{
                    color: #c0b596;
                    display: block;
                    padding: 15px 0 10px;
                    transition: all .3s;
                    font-size: 16px;
                } &:active{
                    color: #c0b596;
                    display: block;
                    padding: 15px 0 10px;
                    transition: all .3s;
                    font-size: 16px;
                }
            }
            &:hover{
                a{
                    font-size: 16px;
                    color: #c0b596;
                    display: block;
                    padding: 15px 0 10px;
                    transition: all .3s;
                    &:hover{
                        color: #c0b596;
                        display: block;
                        padding: 15px 0 10px;
                        transition: all .3s;
                        font-size: 16px;
                    } &:active{
                        color: #c0b596;
                        display: block;
                        padding: 15px 0 10px;
                        transition: all .3s;
                        font-size: 16px;
                    }
                } 
            }
        }
    }
}
.side-bar-collapase{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
}
/* #D4C291 */
.side-bar-collapase i{
font-size: 30px;
color:#D4C291 ;
cursor: pointer;
font-weight: bold;
animation: all 5s;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    background-color: #F5F5F5;
    overflow-x: hidden;
    transition: 0.5s;
 
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  @media(max-width:7000px) and (min-width:991px){
      .sidenav2{
          display: none !important;
      }
  }
  @media(max-width:991px) and (min-width:0px){
    .sidenav1{
        display: none !important;
    }
    .sidenav2{
        margin-top: -56px !important;
        margin-bottom: 20px !important;
    }
}