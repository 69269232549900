.ProfilePageArea {
  margin-top: 60px;
  margin-bottom: 30px;
  .main-profile-image-area {
    padding: 20px 20px 5px 20px;
    border-radius: 10px;
    height: 100%;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(217, 213, 217, 1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(217, 213, 217, 1);
    box-shadow: 0px 0px 6px 0px rgba(217, 213, 217, 1);
    h3 {
      font-size: 20px;
      font-weight: 550;
      img {
        width: 30px;
        margin-right: 15px;
        margin-top: -4px;
      }
    }
    .profile-image-area {
      border: 1px solid #c0b596;
      border-radius: 5px;
      margin-top: 15px;
      padding: 10px;
      position: relative;
      .update-profile-pic-icons {
        position: absolute;
        bottom: 10px;
        right: 10px;
        .profile-upload > input {
          visibility: hidden;
          width: 0;
          height: 0;
        }
        .profile-upload {
          img {
            cursor: pointer !important;
          }
        }
      }
    }
    .update-profile-pic-content1 {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 9px;
        font-weight: 500;
        margin-bottom: 0px;
      }
    }
    .update-profile {
      text-align: right;
      color: white;
      background-color: #cbbc99;
      font-size: 17px;
      padding: 8px 5px 8px 5px;
      border-radius: 5px;
    }
    .update-profile1 {
      text-align: right;
      color: white;
      background-color: #cbbc99;
      font-size: 17px;
      padding: 8px 14px 8px 14px;
      border-radius: 5px;
    }
    .update-profile2 {
      text-align: right;
      color: white;
      background-color: #cbbc99;
      font-size: 17px;
      padding: 8px 23px 8px 23px;
      border-radius: 5px;
    }
    .case-registration-button {
      width: 100%;
      text-align: left;
      color: white;
      background-color: #cbbc99;
      font-size: 16px;
      padding: 10px 5px 10px 15px;
      border-radius: 5px;
      margin-bottom: 20px;
    }
    .registration-guidance-service {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .registration-guidance-service55 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 40px;
      }
    .registration-guidance-service1 {
      display: flex;
      justify-content: space-between;
      align-items: center !important;
      flex-wrap: wrap;
      p {
        font-size: 11px;
        line-height: 20px;
        margin-left: 5px;
        margin-bottom: 0px;
      }
    }
    .ScheduleMeetingProfile-Area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      padding: 8px;
      margin-bottom: 15px;
      p {
        margin-bottom: 0px;
      }
    }
    .counselling-visually-area-details12 {
      p {
        font-size: 10px;
        margin-bottom: 0px;
        line-height: 20px;
      }
    }
    .CounsellingTime {
      color: #58c97c;
      border: 1px solid #58c97c;
      font-size: 12px;
      padding: 5px 2px 5px 2px;
      border-radius: 50%;
    }
    .CounsellingTime1 {
      color: #58c97c;
      border: 1px solid #58c97c;
      font-size: 12px;
      padding: 2px 5px 2px 5px;
      border-radius: 50%;
    }
  }
}

#counselling-table{
margin-top: 20px;
}
#counselling-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  #counselling-table td, #counselling-table th {
  margin-top: 5px;
  border-top: 4px solid white;
    padding: 8px;
  }
  #counselling-table td img{
      width: 20px; height: 20px;
      border-radius: 50%;
      margin-right: 5px;
  }
  
  #counselling-table tr:nth-child(even){background-color: #f2f2f2;}
  #counselling-table tr:nth-child(odd){background-color: #f2f2f2;}
  #counselling-table tr:hover {background-color: #ddd;}
  
  #counselling-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  
    color: white;
  }
  .TOP-heading-corporate-profile{
    margin-bottom: 20px;
    margin-top: -20px;
    font-size: 26px;
    font-weight: 600;
  }
  .main-profile-image-area16{
    padding: 10px 20px 5px 20px;
    border-radius: 10px;
    height: 50px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(217, 213, 217, 1);
    -moz-box-shadow: 0px 0px 6px 0px rgba(217, 213, 217, 1);
    box-shadow: 0px 0px 6px 0px rgba(217, 213, 217, 1);
  }
  .main-profile-image-area16 p{
font-weight: 550;
  }
  .main-profile-image-area16 p img{
    width: 40px;
    border-right: 1px solid #cbbc99;
    padding-right: 10px;
    margin-right: 10px;
  }