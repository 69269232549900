.ourServiceArea{
    background: #f5f5f5;
    padding: 100px 0px 40px;
    .serviceWrap{
        margin-bottom: 60px;
        overflow: hidden;
        @media (max-width:375px) {
            margin-bottom: 40px;
        }
        .serviceIcon{
            float: left;
            border: 1px solid rgba(192, 181, 150, .5);
            border-radius: 50%;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            @media (max-width:1200px) {
                margin-right: 10px;
            }
            i{
                color: #c0b596;
                transition: all .3s;
                &::before{
                    font-size: 45px;
                }
            }
        }
        &:hover{
            .serviceIcon{
                i{
                    color: #999;
                }
            }
        }
        .serviceContent{
            overflow: hidden;
            h3{
                color: #373737;
                font-size: 24px;
                padding-bottom: 10px;
                position: relative;
                margin-bottom: 10px;
                @media (max-width:375px) {
                    font-size: 20px;
                }
                &::before{
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    width: 30px;
                    height: 2px;
                    background: #c0b596;
                }
            }
            p{
                color: #777;
                margin-bottom: 0;
            }
        }
    }
    &.ourServiceAreaStyleTwo{
        background: url(../../images/practice/1.jpg) no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: relative;
        z-index: 1;
        padding: 100px 0px 70px;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(21, 26, 48, .66);
        }
        .sectionTitle{
            h2{
                color: #fff;
            }
        }
        .serviceWrap{
            background: rgba(192, 181, 150, .30);
            margin-bottom: 30px;
            padding: 20px 15px;
            height: 95%;
            .serviceIcon{
                height: 100%;
                border: none;
                width: auto;
                position: relative;
                padding-right: 15px;
                margin:0px;
                margin-right: 15px;
                &::before{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 1px;
                    height: 65px;
                    background: #BC9B41;
                    transform: translateY(-50%);
                }
                i{
                    color: #BC9B41;
                    &::before{
                        font-size: 60px;
                    }
                }
            }
            .serviceContent {
                h3{
                    font-size: 24px;
                    color: #fff;
                    margin-bottom: 10px;
                    padding-bottom: 0;
                    @media (max-width:1100px) {
                        font-size: 20px;
                    }
                    @media (max-width:450px) {
                        font-size: 24px;
                    }
                    &::before{
                        display: none;
                    }
                }
                p{
                    font-size: 16px;
                    color: #ddd;
                    margin-bottom: 0;
                    @media (max-width:450px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    &.ourServiceAreaStyleThree{
        padding: 100px 0px 70px;
        .serviceWrap{
            border: 1px solid #ddd;
            text-align: center;
            padding: 50px 75px;
            margin-bottom: 30px;
            @media (max-width:1024px) {
                padding: 50px;
            }
            .serviceIcon{
                float: none;
                margin-right: 0;
                border: none;
                margin: auto;
                margin-bottom: 15px;
                i{
                    color: #BC9B41/* c0b596 */;
                    &::before{
                        font-size: 60px;
                    }
                }
            }
            .serviceContent{
                h3{
                    padding-bottom: 0;
                    padding-top: 20px;
                    &::before{
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0;
                        height: 1px;
                        width: 65px;
                    }
                }
            }
        }
    }
    &.ourServiceAreaStyleFour{
       
        padding: 100px 0px 70px;
       
        .sectionTitle{
            h2{
                color: #BC9B41;
            }
        }
        .serviceWrap{
            border: 1px solid #ddd;
            margin-bottom: 30px;
            padding: 20px 15px;
            height: 90%;
            .serviceIcon{
                height: 100%;
                border: none;
                width: auto;
                position: relative;
                padding-right: 15px;
                margin:0px;
                margin-right: 15px;
                &::before{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 1px;
                    height: 65px;
                    background: #BC9B41;
                    transform: translateY(-50%);
                }
                i{
                    color: #BC9B41;
                    &::before{
                        font-size: 60px;
                    }
                }
            }
            .serviceContent {
                h3{
                    font-size: 24px;
                    color: #BC9B41;
                    margin-bottom: 10px;
                    padding-bottom: 0;
                    @media (max-width:1100px) {
                        font-size: 20px;
                    }
                    @media (max-width:450px) {
                        font-size: 24px;
                    }
                    &::before{
                        display: none;
                    }
                }
                p{
                    font-size: 16px;
                    color: #666666;
                    margin-bottom: 0;
                    @media (max-width:450px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@import url('https://fonts.googleapis.com/css?family=Mukta:700');
 * {
	 box-sizing: border-box;
}
 *::before, *::after {
	 box-sizing: border-box;
}


 button {
	 position: relative;
	 display: inline-block;
	 cursor: pointer;
	 outline: none;
	 border: 0;
	 vertical-align: middle;
	 text-decoration: none;
	 background: transparent;
	 padding: 0;
	 font-size: inherit;
	 font-family: inherit;
}
 button.learn-more12345 {
	 width: 12rem;
	 height: auto;
}
 button.learn-more12345 .circle12345 {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: relative;
	 margin: 0;
	 width: 3rem;
	 height: 3rem;
	 background:#C0B596;
	 border-radius: 1.625rem;
}
button.learn-more12345 .circle12345-leader {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background:#662d91;
  border-radius: 1.625rem;
}
 button.learn-more12345 .circle12345 .icon12345 {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 margin: auto;
	 background: #fff;
}
 button.learn-more12345 .circle12345 .icon12345.arrow12345 {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 left: 0.625rem;
	 width: 1.125rem;
	 height: 0.125rem;
	 background: none;
}
 button.learn-more12345 .circle12345 .icon12345.arrow12345::before {
	 position: absolute;
	 content: '';
	 top: -0.25rem;
	 right: 0.0625rem;
	 width: 0.625rem;
	 height: 0.625rem;
	 border-top: 0.125rem solid #fff;
	 border-right: 0.125rem solid #fff;
	 transform: rotate(45deg);
}
 button.learn-more12345 .button-text12345 {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 padding: 0.75rem 0;
	 margin: 0 0 0 1.85rem;
	 color: #C0B596;
	 font-weight: 700;
	 line-height: 1.6;
   text-align: center;
   padding-left: 10px;
	
}
 button:hover .circle12345 {
	 width: 100%;
}
 button:hover .circle12345 .icon12345.arrow12345 {
	 background: #fff;
	 transform: translate(1rem, 0);
}
 button:hover .button-text12345 {
	 color: #fff;
}
.button-text12345{
  font-size: 16px !important;
  font-family: wow-bold !important;
}
	
	 #container12345 {
		 grid-area: main;
		 align-self: center;
     justify-self: center;
     margin-top: 10px;
   }




   /* 
     &.ourServiceAreaStyleThree{
        padding: 100px 0px 70px;
        .serviceWrap{
            border: 1px solid #ddd;
            text-align: center;
            padding: 50px 75px;
            margin-bottom: 30px;
            @media (max-width:1024px) {
                padding: 50px;
            }
            .serviceIcon{
                float: none;
                margin-right: 0;
                border: none;
                margin: auto;
                margin-bottom: 15px;
                i{
                  
                    &::before{
                        font-size: 60px;
                    }
                }
            }
            .serviceContent{
                h3{
                    padding-bottom: 0;
                    padding-top: 20px;
                    &::before{
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0;
                        height: 1px;
                        width: 65px;
                    }
                }
            }
        }
    }
   */