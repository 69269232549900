


.wallet-page-top-container {
    background-image: url('../../../images/breadcumb/about.png');
    
   background-repeat: no-repeat;
   background-size: cover;
   background-position: left;
   position: relative;
   width: 100%;
   padding-bottom: 40%; /* 2:1 */
 /*  background-blend-mode: multiply;
   background-color: #7a7878;  */
   &::before{
    content: "";
        background: -moz-linear-gradient(right, rgba(21, 26, 48, 0.79) 8%, rgba(21, 26, 48, 0.79) 46%, rgba(21, 26, 48, 0.07) 95%, rgba(21, 26, 48, 0) 100%);
        background: -webkit-linear-gradient(right, rgba(21, 26, 48, 0.79) 8%, rgba(21, 26, 48, 0.79) 46%, rgba(21, 26, 48, 0.07) 95%, rgba(21, 26, 48, 0) 100%);
        background: linear-gradient(to left, rgba(21, 26, 48, 0.79) 8%, rgba(21, 26, 48, 0.79) 46%, rgba(21, 26, 48, 0.07) 95%, rgba(21, 26, 48, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9151a30', endColorstr='#00151a30', GradientType=1);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
  }
  

  @media(max-width:7000px) and (min-width:1800px){
    .wallet-page-top-content {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction	: column;
      -ms-flex-direction		  : column;
      flex-direction			    : column;
      -webkit-justify-content	: center; 
      justify-content			    : center; 
     
      position: absolute;
      top: 130px; bottom: 0; left: 50%; right: 30px;
      
     }
     .wallet-page-top-content h2{
      position: absolute !important;
      top: 240px !important; bottom: 0 !important; left: -550px !important; right: 0px !important;
      
     }
     .wallet-page-top-content p{
      position: absolute !important;
      top: 50px !important;
      max-width: 33ch !important;
      text-align: justify !important;
     }
     .wallet-page-top-content h2 {
      margin: 0 0 16px;
      margin-top:-160px;
      width: 30ch;
      font-size:60px;
      font-family: 'Playfair Display', serif;
      color: #fff !important;
      font-weight: 700;
     }
     
     .wallet-page-top-content p {
      margin: 0 0 16px;
      max-width: 30ch;
      font-family: 'Playfair Display', serif;
      font-size:28px;
      color:white;
     }
  }
  @media(max-width:1800px) and (min-width:1020px){
    .wallet-page-top-content {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction	: column;
      -ms-flex-direction		  : column;
      flex-direction			    : column;
      -webkit-justify-content	: center; 
      justify-content			    : center; 
     
      position: absolute;
      top: 130px; bottom: 0; left: 51%; right: 30px;
      
     }
     .wallet-page-top-content h2{
      position: absolute !important;
      top: 140px !important; bottom: 0 !important; left: -550px !important; right: 0px !important;
      
     }
     .wallet-page-top-content p{
      position: absolute !important;
      top: 0px !important;
      max-width: 45ch !important;
      text-align: justify !important;
     }
     .wallet-page-top-content h2 {
      margin: 0 0 16px;
      margin-top:-160px;
      width: 30ch;
      font-size:60px;
      font-family: 'Playfair Display', serif;
      color: #fff !important;
      font-weight: 700;
     }
     
     .wallet-page-top-content p {
      margin: 0 0 16px;
      max-width: 30ch;
      font-family: 'Playfair Display', serif;
      font-size:20px;
      color:white;
     }
  }
  @media(max-width:1020px) and (min-width:0px){
    .wallet-page-top-content {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction	: column;
      -ms-flex-direction		  : column;
      flex-direction			    : column;
      -webkit-justify-content	: center; 
      justify-content			    : center; 
     
      position: absolute;
      top: 130px; bottom: 0; left: 30px; right: 30px;
      
     }
     .wallet-page-top-content h2 {
      margin: 0 0 16px;
      margin-top: 50px;
      color: #fff !important;
     }
     
     .wallet-page-top-content p {
      margin: 0 0 16px;
      color: #fff !important;
      max-width: 40ch;
     }
  }
  @media only screen and (max-width: 1020px) {
  
   .wallet-page-top-container {
     padding-bottom: 30%;
   }
   .wallet-page-top-content {
     position: relative;
     top: 0; bottom: 0; left: 0; right: 0;
     padding: 30px 20px 30px 3px;
   }
  
  }
  
  /* Optional CSS for Codepen demo only */
  
  .wallet-page-top-content {
   font-size: 120%;
   color: #fff !important;
   
  }
  @media(max-width:7000px) and (min-width:920px){
    
  }
  @media(max-width:920px) and (min-width:0px){
    
  }
  
  
  .wallet-page-top-content p:last-of-type {
   margin-bottom: 0;
  }
  
  
  
  