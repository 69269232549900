.CounsellingPaid{
  /*   background: url(../../images/contact/sign-in--page-background.jpg) center center/cover no-repeat local;
    */ position: relative;
    background-attachment: fixed;
    padding: 100px 0px;
    &::before{
        content: "";
   /*      background-color: rgba(21, 26, 48, 0.8); */
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .contactInfo{
        @media (max-width:768px) {
            margin-bottom: 40px;
        }
        span{
            font-size: 16px;
            color: #ada282;
        }
        h2{
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #fff;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }
        h6{
            font-size: 18px;
            color: #c0b596;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #ddd;
            line-height: 30px;
        }
    }
}
.profile-info .profile-info-company-logo {
    display: none !important;
  }
  .calendly-inline-widget{
    scrollbar-color: red yellow;
}
.password-area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: red;
    font-size: 600;
}
.password-area1{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap; 
}
.signon-bottom-area{
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
#emailField{
    padding:17px;
    padding-left:60px;
    padding-right: 40px;
  border-right: 1px solid #c0b596;
  background-image:url('../../images/signIn/03-01.png') ;
  background-repeat:no-repeat;
  background-position:left center;
  outline:0; 
  }
.field-design{
  background: rgba(192, 181, 150, 0.2);
    height: 50px;
    padding: 6px 15px;
    color: white;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, 0.5);
}
.field-design1{
    background: rgba(192, 181, 150, 0.2);
      height: 50px;
      padding: 6px 15px;
      color: white;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid rgba(192, 181, 150, 0.5);
  }
.field-design img{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.password-image2{
    width: 30px;
    cursor: pointer;
}
.password-image1{
    width: 35px;
    border-right: 1px solid #c0b596;
    padding-right: 10px;
}
.field-design input{
    width: 85% !important;
    border-color: transparent !important;
    outline: none !important;
}
.field-design99{
    display: flex;
    justify-content: space-between;
    color: #666666 !important;
    background-color: #F3F0EA;
    height: 50px;
    padding: 10px 10px 5px 10px;
}
.field-design input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.field-design1 input{
    width: 78% !important;
    border-color: transparent !important;
    outline: none !important;
}
.field-design1 input:focus{
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.counselling-images-area{
 /*    width: 100%;
    height: 2px;
    background-color: #CABC99;  */
    background-color: white;
}
.counselling-images-area img{
  /*   display: inline-block;
  vertical-align: middle; */
    z-index: 1000 !important;
    margin-left: 40px;
    width: 130px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
    -moz-box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
    box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
    border-radius: 50%;
    background-color: white;
}
.line-Bar-style{
    border: 1px solid #CABC99;
    margin-top: -60px;
    z-index: -100 !important;
}
.counselling-left-content-area{
    margin-top: 90px;
}
.counselling-left-content-area h2{
    font-size: 20px;
    font-weight: 600;
}
.counselling-left-content-area1{
    margin-top: 40px;
}
.counselling-left-content-area1 h2{
    font-size: 24px;
    font-weight: 600;
}
.counselling-left-content-area-search{
    margin-top: 40px;
}
.counselling-left-content-area-search p{
font-size: 16px;
color: #666666;
font-weight: 500;
}

.counselling-search-bar{
    position: relative;
    margin-bottom: 60px;
    @media (max-width:768px) {
        margin-top: 60px;
    }
    input{
        height: 50px;
        font-size: 17px;
        padding: 6px 50px 6px 20px;
        transition: all 0.3s;
        border: 1px solid #ddd;
        color: #333;
        border-radius: 0px;
        width: 90%;
        box-shadow: none;
        &:focus{
            box-shadow: none;
            outline: none;
            border-color: #666
        }
    }
    button{
        background: #c0b596;
        font-size: 20px;
        color: #fff;
        border: 0;
        outline: 0;
        position: absolute;
        height: 50px;
        width: 50px;
        cursor: pointer;
        right: 0;
        top: 0;
    }
}
.counselling-right-content-area{
    -webkit-box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
    -moz-box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
    box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
    padding: 30px;
}

.checkbow-style12{
    height: 20px;
    width: 20px;
    background-color: #c0b596 !important;
    margin-top: 8px;
   
}
.checkbow-style-area12{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
input[type=checkbox]:after {
    position: absolute;
    width: 20px;
    height: 20px;
  margin-top: 8px;
    top: 0;
    content: " ";
    background-color: white;
    border: 1px solid #c0b596;
    color: black;
    display: inline-block;
    visibility: visible;
    padding: 0px 0px 3px 3px !important;
    border-radius: 3px;
  }
  
  input[type=checkbox]:checked:after {
	  content: "✓";
      font-size: 16px;
      font-weight: 600;
  
  }
.checkbow-style-area input:checked ~ .checkmark:after {
    display: block;
  }
  .counselling-right-content-area-header h2{
      font-size: 24px;
      font-weight: 500;
  }
  .counselling-right-content-area-header{
      border-bottom: 2px solid #c0b596;
  }
  .counselling-right-content-area-form-content{
margin-top: 20px;
  }
  .counselling-right-content-area-form-content h2{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}
.counselling-right-form-content-personal22 h2{
    margin-top: 50px;
    font-size: 24px;
    font-weight: 500;
    color: #c0b596;
    margin-bottom: 20px; 
}
.counselling-right-form-content-personal11 h2{
    margin-top: 50px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px; 
}
.switch-toggle {
    width: 10em;
  }
  
  .switch-toggle label:not(.disabled) {
    cursor: pointer;
  }
  .counselling-visually-area-details{
      border: 3px dotted #c0b596;
      padding: 15px;

  }
  .counselling-visually-area-details p{
      margin: 0px !important;
  }