.headerArea{
    .headerBottomArea{
        background: #353535;
        position: relative;
      
        .logo {
            padding: 0px 0px;
            @media (max-width:1000px) {
                padding: 15px 0;
                margin-top: 0;
            }
            img{
                @media (max-width:1024px) {
                    height: unset;
                }
                @media (max-width:768px) {
                    height: 50px;
                }
                @media (max-width:767px) {
                    height: 40px;
                }
                @media (max-width:400px) {
                    height: unset;
                }
            }
        }
        .mainMenuWrap{
         
            @media (min-width:1100px) {
              text-align: right;
             /*  margin-left: 55px; */
            }
            li{
                display: inline-block;
                position: relative;
                padding: 0px 10px;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
                @media (max-width:1200px) {
                    padding: 0px 15px;
                }
                a{
                    display: block;
                    font-size: 15px;
                    font-weight: 400;
                    color: #fff;
                    letter-spacing: 0;
                    padding: 24px 0px;
                    text-transform: uppercase;
                    cursor: pointer;
                    i{
                        display: none;
                    }
                    @media (max-width:1200px) {
                        font-size: 11px;
                    }
                    &.active{
                        color: #c0b596;
                        @media (min-width:1000px) {
                          border-bottom:5px solid #c0b596;
                        }
                       
                    }&.hover{
                     
                  }
                }
                
                &:hover{
                  a{
                    display: block;
                    font-size: 15px;
                    font-weight: 400;
                    color: #fff;
                    letter-spacing: 0;
                    padding: 24px 0px;
                    text-transform: uppercase;
                    @media (min-width:1000px) {
                      border-bottom:5px solid #c0b596;
                    }
                    cursor: pointer;
                    i{
                        display: none;
                    }
                    @media (max-width:1200px) {
                        font-size: 11px;
                    }
                    &.active{
                        color: #c0b596;
                    }
                }
                    span{
                        color: #c0b596;
                    }
                }
                ul.subMenu{
                    position: absolute;
                    left: 0;
                    top: 78px;
                    z-index: 999;
                    width: 226px;
                    padding: 5px 12px;
                    background: #fff;
                    text-align: left;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
                    -webkit-transform: scaleY(0);
                    -ms-transform: scaleY(0);
                    -o-transform: scaleY(0);
                    transform: scaleY(0);
                    transform-origin: top;
                    -webkit-transition: all 0.3s ease 0s;
                    -o-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                    text-transform: capitalize;
                    li{
                        display: block;
                        padding: 0;
                        border-bottom: 1px solid #f2f2f2 !important;
                        &:last-child{
                            border-bottom: none;

                        }
                        a{
                            font-size: 15px;
                            color: #06163a;
                            display: block;
                            border-bottom: 1px solid #f2f2f2 !important;
                            padding: 12px 15px;
                            text-transform: capitalize;
                            &:hover,
                            &.active{
                              border-bottom: 1px solid #f2f2f2 !important;
                                color: #c0b596;
                            }
                        }
                    }
                }
                ul.subMenu1{
                  position: fixed;
                  top: 168px;
                  margin: 0% auto; /* Will not center vertically and won't work in IE6/7. */
                left: 0;
                 right: 0;
                  z-index: 999;
                  max-width: 1100px;
                  padding: 5px 12px;
                  background: #CDB45C;
                  text-align: left;
                  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
                  -webkit-transform: scaleY(0);
                  -ms-transform: scaleY(0);
                  -o-transform: scaleY(0);
                  transform: scaleY(0);
                  transform-origin: top;
                  -webkit-transition: all 0.3s ease 0s;
                  -o-transition: all 0.3s ease 0s;
                  transition: all 0.3s ease 0s;
                  text-transform: capitalize;
                  li{
                      display: block;
                      padding: 0;
                     /*  border-bottom: 1px solid #f2f2f2; */
                      &:last-child{
                          border-bottom: none;

                      }
                      a{
                          font-size: 1rem;
                          color: white;
                          display: block;
                  /*         border-bottom: 1px solid #f2f2f2; */
                          padding: 12px 1px;
                          text-transform: capitalize;
                          &:hover,
                          &.active{
                     /*   border-bottom: 1px solid #f2f2f2;  */
                              color: #686764;
                          }
                      }
                  }
              }
              &:hover{
                ul.subMenu1{
                    -webkit-transform: scaleY(1);
                    -ms-transform: scaleY(1);
                    -o-transform: scaleY(1);
                    transform: scaleY(1);
                }
            }
                &:hover{
                    ul.subMenu{
                        -webkit-transform: scaleY(1);
                        -ms-transform: scaleY(1);
                        -o-transform: scaleY(1);
                        transform: scaleY(1);
                    }
                }
            }
        }
        .responsiveWrapper{
            @media (max-width:1000px) {
                display: none;
                position: absolute;
                left: 0;
                top: 100%;
                width: 100%;
                background: #151a30;
                transition: all .3s;
                z-index: 99;
                padding: 20px;
                &.active{
                    display: block;
                }
                .mainMenuWrap{
                    text-align: left;
                    li{
                        display: block;
                        padding: 0;
                        margin-bottom: 15px;
                        a,
                        span{
                            padding: 0px;
                            position: relative;
                            i{
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        ul.subMenu{
                            top: 25px;
                            li{
                                margin-bottom: 0;
                                a{
                                    padding: 10px 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .searchMenuWrapper{
            display: flex;
            justify-content: flex-end;
            height: 100%;
            align-items: center;
            .searchWrap{
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                i{
                    color: #fff;
                    cursor: pointer;
                    &:hover{
                        color: #c0b596;
                    }
                }
                .searchform{
                    position: absolute;
                    right: 0;
                    top: 150%;
                    width: 263px;
                    background: #fff;
                    z-index: 999;
                    padding: 15px;
                    visibility: hidden;
                    opacity: 0;
                    transition: all .3s;
                    text-align: center;
                    @media (max-width:380px) {
                        right: -25px;
                    }
                    &.active{
                        visibility: visible;
                        opacity: 1;
                        top: 100%;
                    }
                    form{
                        position: relative;
                        input{
                            background: #fff;
                            width: 100%;
                            height: 40px;
                            padding: 0 40px 0 10px;
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                        }
                        button{
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 40px;
                            height: 40px;
                            background: #272c3f;
                            color: #fff;
                            border: none;
                            cursor: pointer;
                            &:hover{
                                background: #1d233c;
                            }
                        }
                    }
                    
                }
            }
            .responsiveTrigger{
                margin-left: 30px;
                cursor: pointer;
                display: none;
                @media (max-width:1000px) {
                    display: block;
                }
                span{
                    width: 25px;
                    height: 4px;
                    background: #fff;
                    display: block;
                    margin-bottom: 4px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.headerBottomAreaStyelTwo{
            position: absolute;
            left: 0;
            width: 100%;
            z-index: 99;
            background: rgba(49, 49, 49, 0.5);
           
            border-bottom: 1px solid rgba(255,255,255,.07);
            
            @media (max-width:1000px) {
                position: relative;
                background: #272c3f;
            }
            
        }
    }
    &.headerAreaStyleThree{
        .headerBottomArea{
            background: transparent;
            @media (max-width:768px) {
                background: #272c3f;
            }
            .headerBottomMainWrapper{
                background: #272c3f;
                padding: 0px 15px;
                @media (max-width:768px) {
                    padding: 0;
                }
            }
        }
    }
}
@media(max-width:7000px) and  (min-width:500px){
    /* style={{color:'#9B9176',fontSize:'20px',fontWeight:'bolder'}} */
    .logo-text{
        color: #9B9176;
        font-size: 20px;
        font-weight: bold;

    }
}
@media(max-width:500px) and  (min-width:0px){
    /* style={{color:'#9B9176',fontSize:'20px',fontWeight:'bolder'}} */
    .logo-text{
        color: #9B9176;
        font-size: 16px;
        font-weight: bold;
        
    }
}
.progress-bar {
    position: fixed !important;
    top: 0;
    height: 8px;
    background: #353535 !important;
    width: 0%;
    z-index: 10;
  }
  .progress-container {
      position: fixed !important;
      top: 0;
    width: 100%;
    height: 8px;
    background: #ccc;
    z-index: 10;
  }
  .showCookies{
    display: block;
  }
  .hideCookies{
    display: none;
  }
  
  @media(max-width:7000px) and (min-width:500px){
    .toast-postion-of-notify{
      position:fixed;
      bottom:0px;
      left:50%;
      z-index:1000;
      transform: translate(-50%, -0%);
      margin: 0 auto;
     /*  background-color: white; */
      } 
  }
  @media(max-width:500px) and (min-width:0px){
    .toast-postion-of-notify{
      position:fixed;
      top:180px;
      left: 10%;
      z-index:1000;
      width: 80%;
      margin: 0 auto;
     /*  background-color: white; */
      }
  }
  .toast{
    max-width:1000px;
    z-index:1000;
    animation: move 5s linear 5s infinite;
    /* background-color: white; */
  }
  @-webkit-keyframes move {
    from {
      right: 0%;
    }
    to {
      right: 30%;
    }
  }
  .adjust-the-cookies-policies-button{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .website-text-notify{
    font-family: 'Open Sans', sans-serif;

    font-size:12px;
    color: white;
  }
  .website-heading-notify{
    font-family: 'Open Sans', sans-serif;

    font-size:12px;
    color: white;
  }  


  #google_translate_element select::-webkit-scrollbar
{
	width: 12px;
	background-color: rgba(49, 49, 49, 0.5);
}
.skiptranslate .goog-te-gadget{
  height: 18px !important;
}
#google_translate_element select::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}

#google_translate_element{
  background-color: rgba(49, 49, 49, 0.5);
  padding: 0px 0px 0px 0px !important;
  border-radius: 5px;
}
select.goog-te-combo option{
  background-color: rgba(49, 49, 49, 0.5) !important;
  color: white;
  padding-left: 5px !important;
font-size: 20px !important;
  font-family: wow-regular;
  text-transform: capitalize !important;
}
select.goog-te-combo option:hover{
    background-color: rgba(49, 49, 49, 0.5) !important;
    color: white;
    padding-left: 5px !important;
  border: none !important;
  outline: none !important;
    font-family: wow-regular;
    text-transform: capitalize !important;
  }
  /* turn it off completely */
select.goog-te-combo:active, select.goog-te-combo:hover {
    outline: none
  }
  
  select.goog-te-combo:active, select.goog-te-combo:hover {
    outline-color: red !important;
  }
  
.goog-te-gadget img{
    display: none !important;
}

@media(max-width:7000px) and (min-width:991px){
  select.goog-te-combo{
  
    padding-left: 0px !important;
    font-size: 11px;
    background-color: rgba(49, 49, 49, 0.5);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding-top: 1px;
    padding-bottom: 0px  !important;
    outline: none; 
  }
}
@media(max-width:991px) and (min-width:0px){
  select.goog-te-combo{
  
    padding-left: 0px !important;
    font-size: 11px;
    background-color: rgba(49, 49, 49, 0.5);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 6px !important;
    outline: none; 
  }
}
select.goog-te-combo:hover{
    border: none;  
    outline: none; 
}

select.goog-te-combo:active{
    border: none; 
    outline: none;  
}
select.goog-te-combo:visited{
    outline: none;
    border: none;   
}
  @media (max-width: 667px) {
    #google_translate_element {
      bottom: calc(100% - 50% - 53px);
      left: 16px !important;
      width: 100% !important;
   }
    #google_translate_element goog-te-gadget {
      width: 100% !important;
   }
    #google_translate_element .skiptranslate {
      width: 100% !important;
   }
    #google_translate_element .goog-te-gadget-simple {
      width: calc(100% - 32px) !important;
   
   }
  }
  .goog-te-gadget-simple .goog-te-menu-value span{
    color:white !important;
  }
  
  @media(max-width:7000px) and (min-width:1000px){
    .goog-te-gadget .goog-te-combo{
        margin-bottom: 0px !important;
        text-transform: capitalize;
        max-width: 78px !important;
       /*  padding:9px 7px 13px 7px !important; */
        cursor: pointer;
    }
  }
 
  @media(max-width:1000px) and (min-width:0px){
    .goog-te-gadget .goog-te-combo{
        margin-bottom: 0px !important;
        text-transform: capitalize;
        max-width: 65px !important;
        cursor: pointer;
    }
  }
  .goog-logo-link, .goog-logo-link:link, .goog-logo-link:visited, .goog-logo-link:hover, .goog-logo-link:active{
      display: none;
  }
  #google_translate_element:empty {
    display: none;
 }

  @media(max-width:7000px) and  (min-width:1000px){
    #google_translate_element {
      background: transparent;
      font-size: 10px !important;
      font-weight: 700;
      color: #fff;
      height: 37px !important;
      padding: 10px 7px !important;
      border: 2px solid #c0b596;
      border-radius: 47px;
     /*  padding:9px 7px 13px 7px !important; */
      text-transform: capitalize;
      @media (max-width:1024px) {
          font-size: 12px;
          padding: 9px;
      } 
        overflow: hidden;
        /* position: fixed;
        bottom: calc(53px + 16px);
        right: 16px !important; */
      
      }
      .goog-te-gadget {
        font-family: Roboto, 'Open Sans', sans-serif !important;
        color:transparent !important ;
        margin-top: -5px;
      
      }
  }
  @media(max-width:1000px) and  (min-width:0px){
    #google_translate_element {
      background: transparent;
      font-size: 10px !important;
      font-weight: 700;
      color: #fff;
      height: 37px !important;
      padding: 0px 7px !important;
      border: 2px solid #c0b596;
      border-radius: 47px;
      text-transform: capitalize;
      @media (max-width:1024px) {
          font-size: 12px;
          padding: 9px;
      } 
        overflow: hidden;
        /* position: fixed;
        bottom: calc(53px + 16px);
        right: 16px !important; */
      
      }
      .goog-te-gadget {
        font-family: Roboto, 'Open Sans', sans-serif !important;
        color:transparent !important ;
        margin-top: 0px;
      
      } 
  }
  .jss601{
    background-color: rgba(49, 49, 49, 0.5);
  }
  .goog-te-gadget-simple {
    background-color: rgba(49, 49, 49, 0.5) !important;
    border: 1px solid rgba(49, 49, 49, 0.5)!important;
  /*   padding: 8px 0px 8px 8px !important; */
    border-radius: 4px !important;
    line-height: 1.5rem !important;
    display: inline-block;
    cursor: pointer;
    zoom: 1;
     
  }
  .goog-te-menu-frame{
    max-width: 100%;
    width: 100% !important ;
    /* overflow: scroll !important; */
  } 
   .goog-te-menu2 {
    max-width: 100%;
    width: 50% !important ;
    /* overflow: scroll !important; */
  } 
   .goog-te-menu-value {
    color: #fff !important;
  } 
  
  .goog-te-menu-value {
    color: #fff !important;
    /* font-family: fantasy; */
    content: "\E927";
    margin-right: 16px;
    font-size: 1rem;
    /* vertical-align: -10px; */
  }
 
  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }
  body {
    
    top: 0px !important;
  }
  #goog-gt-tt{
    display: none !important;
  }
  .goog-text-highlight{
      display: none !important;
    background: transparent!important;
    /* display: none !important; */
    border: none!important;
    box-shadow: none !important;
    outline: none !important;
  }
  
  
  @media (max-width: 667px) {
    #google_translate_element {
      bottom: calc(100% - 50% - 53px);
      left: 16px !important;
      width: 100% !important;
   }
    #google_translate_element goog-te-gadget {
      width: 100% !important;
   }
    #google_translate_element .skiptranslate {
      width: 100% !important;
   }
    #google_translate_element .goog-te-gadget-simple {
      width: calc(100% - 32px) !important;
   
   }
  }
  .goog-te-gadget-simple .goog-te-menu-value span{
    color:white !important;
  }
  
  .goog-logo-link, .goog-logo-link:link, .goog-logo-link:visited, .goog-logo-link:hover, .goog-logo-link:active{
      display: none !important;
  }

  .goog-tooltip {
    display: none !important;
}
.goog-tooltip:hover {
    display: none !important;
}
#goog-gt-tt, .goog-te-balloon-frame{display: none !important;} 
.goog-text-highlight { background: none !important; box-shadow: none !important;}
.mainMenuWrap8888{
  text-align: left !important;
}
@media(max-width:7000px) and (min-width:1000px){
  .our-services-mobile{
display: none !important;
  }
}
@media(max-width:1000px) and (min-width:0px){
  .our-services-pc{
    display: none !important;
      }
}
.services-practive-border{
  border-bottom:2px solid #f2f2f2 !important;
  border-image: linear-gradient(to right, #f2f2f2 40%, transparent 40%); /* to top - at 50% transparent */
  border-image-slice: 1;
  color: #565656;
  text-transform: uppercase;
}
.services-practive-border1{
  border-bottom:2px solid #f2f2f2 !important;
  border-image: linear-gradient(to right, #f2f2f2 48%, transparent 48%); /* to top - at 50% transparent */
  border-image-slice: 1;
  color: #565656;
  text-transform: uppercase;
}
.services-practive-border2{
  border-bottom:2px solid #f2f2f2 !important;
  border-image: linear-gradient(to right, #f2f2f2 62%, transparent 62%); /* to top - at 50% transparent */
  border-image-slice: 1;
  color: #565656;
  text-transform: uppercase;
}