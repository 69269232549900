.counterArea{
    padding: 100px 0px;
    background: url(../../images/counter/1.jpg)  center center/cover no-repeat local;
    .counterMainWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .counterWrap{
            flex: 0 0 49.5%;
            width: 49.5%;
            background: rgba(32, 31, 27, 0.3);
            margin-bottom: 5px;
            min-height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            .count{
                font-size: 50px;
                font-weight: bold;
                color: #fff;
                margin: 0;
                line-height: 10px;
                font-family: 'Playfair Display', serif;
                @media (max-width:375px) {
                    font-size: 30px;
                }
                span{
                    &.level{
                        font-weight: 300;
                    }
                }
            }
            p{
                font-size: 18px;
                font-weight: normal;
                color: #c0b596;
                margin-bottom: 0;
                @media (max-width:375px) {
                    font-size: 15px;
                }
            }
        }
    }
    &.counterAreaStyleTwo{
        background: url(../../images/counter/2.jpg) center center/cover no-repeat local;
        background-attachment: fixed;
        position: relative;
        &::before{
            content: "";
            background-color: rgba(21, 26, 48, 0.9);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        .counterMainWrapper{
            .counterWrap{
                flex: 0 0 24.5%;
                width: 24.5%;
                @media (max-width:768px) {
                    width: 49.5%;
                    flex: 0 0 49.5%;
                }
                @media (max-width:450px) {
                    width: 100%;
                    flex: 0 0 100%;
                }
            }
        }
    }
}