.headerArea{
    .headerTop {
        .headerTopMainWrapper{
            border-bottom: 1px solid rgba(255,255,255,.07);
            padding: 0px 0;
            .mainMenuWrap{
            @media (min-width:900px) {
                display: flex;
                justify-content: flex-end !important;
                flex-wrap: wrap; 
            }
            @media (max-width:900px) {
                display: flex;
                justify-content: center !important;
                flex-wrap: wrap; 
            }
      
                li{
              
                    display: inline-block;
                    position: relative;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    @media (max-width:1200px) {
                        padding: 0px 2px !important;
                        margin: 2px 0px 2px 0px;
                    }
                    a{
                        display: block;
                        font-size: 16px !important;
                        font-weight: 400;
                        color: #fff;
                        letter-spacing: 0;
                        padding: 5px 5px !important;
                     margin-top: 1px;
                     margin-bottom: 3px;
                        cursor: pointer;
                        i{
                           
                        }
                        @media (max-width:1200px) {
                            font-size: 16px;
                        }
                        &.active{
                            color: #c0b596;
                        }
                    }
                    
                    &:hover{
                        a{
                            display: block;
                            font-size: 16px !important;
                            font-weight: 500;
                            color: #fff;
                            letter-spacing: 0;
                            padding: 5px 5px;
                         margin-top: 1px;
                         margin-bottom: 3px;
                            cursor: pointer;
                            i{
                               
                            }
                            @media (max-width:1200px) {
                                font-size: 16px;
                            }
                            &.active{
                                color: #c0b596;
                            }
                        }
                        span{
                            color: #c0b596;
                        }
                    }
                    ul.subMenu{
                        position: absolute;
                        left: 0;
                        top: 50px;
                        z-index: 999;
                        width: 226px;
                        padding: 5px 5px;
                        background: #fff;
                        text-align: left;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
                        -webkit-transform: scaleY(0);
                        -ms-transform: scaleY(0);
                        -o-transform: scaleY(0);
                        transform: scaleY(0);
                        transform-origin: top;
                        -webkit-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        text-transform: capitalize;
                        font-size: 20px !important;
                        li{
                            font-size: 20px !important;
                            display: block;
                            padding: 0;
                            border-bottom: 1px solid #f2f2f2;
                            &:last-child{
                                border-bottom: none;
    
                            }
                            a{
                                @media (min-width:1000px) {
                                    font-size: 12px !important;
                                    font-weight: 550;
                                }
                                  
                               
                                color: #06163a;
                                display: block;
                                padding: 5px 15px;
                                text-transform: capitalize;
                                &:hover,
                                &.active{
                                    color: #c0b596;
                                }
                              i{
                                  font-size: 16px;
                              }  
                            }
                        }
                    }
                    &:hover{
                        ul.subMenu{
                            -webkit-transform: scaleY(1);
                            -ms-transform: scaleY(1);
                            -o-transform: scaleY(1);
                            transform: scaleY(1);
                        }
                    }
                }
            }
            .responsiveWrapper{
                @media (max-width:800px) {
              
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100%;
                    background: #151a30;
                    transition: all .3s;
                    z-index: 99;
                    padding: 20px;
                    &.active{
                        display: block;
                    }
                    .mainMenuWrap{
                        text-align: left;
                        li{
                            display: block;
                            padding: 0;
                            margin-bottom: 15px;
                            a,
                            span{
                                padding: 0px;
                                position: relative;
                                i{
                                    display: block;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            ul.subMenu{
                                top: 25px;
                                li{
                                    margin-bottom: 0;
                                    a{
                                        padding: 10px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        background: #353535;
        ul{
            text-align: left;
            @media (max-width:576px) {
                text-align: center;
            }
            li{
                display: inline-block;
                padding: 6px 4px 3px 0px;
                color: #fff;
                font-size: 11px !important;
                color: #e0e0e0;
                &:first-child{
                 /*    position: relative;
                    padding-left: 0;
                    &::before{
                        position: absolute;
                        content: "";
                        right: 0;
                        top: 14px;
                        width: 1px;
                        height: 25px;
                        background: rgba(255,255,255,.07);
                    } */
                    
                }
                &:last-child{
                    padding-right: 0;
                }
                i{
                    padding-right: 2px;
                    color: #cbbc99;
                    
                }
            }
            &.accountLoginArea{
                @media (max-width:576px) {
                    justify-content: center!important;
                }
                li{
                    padding-left: 0;
                    @media (max-width:768px) {
                       padding: 0;
                    }
                    &::before{
                   
                    }
                }
                
            }
        }
        .btnStyle {
            @media (max-width:576px) {
                text-align: center!important;
            }
        }
        .btnStyle{
            a{
                background: transparent;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                padding: 9px 15px;
                border: 2px solid #c0b596;
                border-radius: 47px;
                text-transform: capitalize;
                @media (max-width:1024px) {
                    font-size: 11px;
                    padding: 9px;
                }
            }
        }
    }
 
    &.headerAreaStyleThree{
        .headerTop{
            background: transparent;
            padding: 0;
            @media (max-width:768px) {
                background: #151a30;
            }
            .headerTopMainWrapper{
                padding: 10px 15px;
                background: #151a30;
                @media (max-width:768px) {
                    padding: 10px 0;
                }
                .btnStyle {
                    a{
                        padding: 9px 13px;
                    }
                }
            }
        }
    }
}
.livemeeting a i{
 color: #c0b596;   
}
@media (max-width:7000px) and (min-width:1200px){
    .livemeeting{
        a{
            background: transparent;
            font-size: 11px !important;
            font-weight: 700;
            color: #fff;
            padding: 5px 11px;
            border: 2px solid #c0b596;
            border-radius: 47px;
            text-transform: capitalize;
            @media (max-width:1024px) {
                font-size: 11px;
                padding: 9px;
            }  
        }
    }
    .livemeeting1{
      
    }
}
@media (max-width:1200px) and (min-width:515px){
    .livemeeting{
        a{
            background: transparent;
            font-size: 10px !important;
            font-weight: 700;
            color: #fff;
            padding: 9px 15px;
            border: 2px solid #c0b596;
            border-radius: 47px;
            text-transform: capitalize;
            @media (max-width:1024px) {
                font-size: 12px;
                padding: 9px;
            }  
        }
    }
    .livemeeting1{
      
    }
}
@media (max-width:515px) and (min-width:0px){
    .mymeeting{
        margin-top: 5px;
    }
    .livemeeting{
       
    }
    .my-class-number-space{
        margin-left: 20px !important;
    }
    .my-class-time{
        display: none !important;
    }
    .livemeeting{
        a{
            background: transparent;
            font-size: 10px !important;
            font-weight: 700;
            color: #fff;
            padding: 9px 15px;
            border: 2px solid #c0b596;
            border-radius: 47px;
            text-transform: capitalize;
            @media (max-width:1024px) {
                font-size: 12px;
                padding: 9px;
            }  
        }
    }
    .meetingClass{
       /*  display: flex;
        justify-content: space-between;
        flex-wrap: wrap; */
    }  
}

.heading-modal{
    font-family: 'Playfair Display', serif;
}
.close{
    font-size: 2.5rem !important;
    font-weight: 600 !important;
}
.modal-header{
    border-bottom: 3px solid #D4C291 !important;
}
.google-captcha-additionarea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.sent-message-area{
    padding: 50px 0px 50px 0px;
}
.sent-message-area h2{

}
.sent-message-area p i{
    font-size: 200px;
    color:#D4C291 ;
}
.sent-button-des{
    color: white;
    background-color: #D4C291;
    font-size: 20px;
    padding: 5px 50px 5px 50px;
    border-radius: 5px;
}
/* @media (min-width: 992px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 1410px !important;
}
} */
.upload-photo-area{
    width: 100%;
    height: 82%;
    background-color: #F3F0E9;
    color: #D0C4AC;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D0C4AC;
}
.inputfile-box18 {
    position: relative;
    border: 1px solid #D0C4AC;
    background-color: #F3F0E9;
    color: #666666 !important;
    padding: 12px;
    height: 50px;
  }
  
  .inputfile18 {
    display: none;
  }
  
  .container18 {
    display: inline-block;
    width: 100%;
  }
  
  .file-box18 {
    display: inline-block;
    width: 100%;
    border: 1px solid;
    
    padding:6px 15px;
    box-sizing: border-box;
    height: 50px;
  }
  
  .file-button18 {
    background: #D0C4AC;
    color: white;
    padding: 12px;
    cursor: pointer;
    position: absolute;
    border: 1px solid;
    top: 0px;
    right: 0px;
    height: 50px;
  }
  .image-upload > input {
    visibility:hidden;
    width:0;
    height:0
  }
  .image-upload{
      cursor: pointer;
  }
  
  @media (max-width:7000px) and (min-width:991px){
    .myaddressphoneclass{
        margin-top: 8px !important;
        display: flex !important;
        justify-content: flex-start !important;
    }
  }
  @media (max-width:991px) and (min-width:0px){
    .myaddressphoneclass{
        margin-top: 8px !important;
        display: flex !important;
        justify-content: center !important;
    } 
}